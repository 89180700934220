<template>
  <v-dialog
    v-model="createTheme"
    max-width="600"
    @click:outside="close()"
  >
    <v-card
      max-width="600"
      class="mx-auto"
    >
      <v-container>
        <v-card-title
          class="headline"
        >
          Créer un thème
        </v-card-title>
        <form>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                outlined
                v-model="theme.name"
                label="Intitulé"
                @blur="createSlug"
                hint="Exemple : Développement mobile"
                :rules="[v => !!v || 'Indiquez l\'intitulé']"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                outlined
                v-model="theme.slug"
                label="Slug"
                hint="Exemple : developpement-mobile"
                :rules="[v => !!v || 'Indiquez le slug']"
                spellcheck="false"
                required
              />
            </v-col>
          </v-row>
          <v-card-actions>
          <v-spacer />
            <v-btn
              color="red"
              @click="close()"
            >
              Fermer
            </v-btn>
            <v-btn
              color="success"
              @click="create()"
            >
              Créer le nouveau thème
            </v-btn>
          </v-card-actions>
        </form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import slugify from 'slugify';
  import { mapMutations } from 'vuex';

  export default {

    name: 'CreateTheme',

    data() {
      return {
        createTheme: false,
        theme: {
          name: '',
          slug: '',
        },
      }
    },

    methods: {
      show() {
        this.createTheme = true;
      },

      close() {
        this.createTheme = false;
      },

      createSlug() {
        if (!this.theme.slug) {
          this.theme.slug = slugify(this.theme.name, { lower: true });
        }
      },

      create() {
        this.axios.post('/themeCours', this.theme)
          .then((res) => {
            this.setSnack({ text: 'Le thème a bien été créé', type: 'success' });
            this.close();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la création du thème', type: 'warn' });
          });
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },

  }
</script>
